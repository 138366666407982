import { useEffect, useState } from "react";
import * as React from "react";

function MembershipDescTicketPartOne({ data, launch }) {
  const [mintDate, setMintDate] = useState({});

  useEffect(() => {
    if (!data || !data["mint-start-time"] || !data["mint-end-time"]) {
      return;
    }
    let d = new Date(data["mint-start-time"]);
    if (launch) {
      d = new Date(data["mint-end-time"]);
    }
    let time = new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(d);
    time = time.toUpperCase();

    let month = new Intl.DateTimeFormat("default", {
      month: "short",
    }).format(d);
    month = month.toUpperCase();

    let weekday = new Intl.DateTimeFormat("default", {
      weekday: "short",
    }).format(d);
    weekday = weekday.toUpperCase();

    setMintDate({
      time: time,
      month: month,
      weekday: weekday,
      date: d.getDate(),
      year: d.getFullYear(),
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "80vw",
        height: "calc(80vw * 0.23)",
        maxWidth: "400px",
        // border: "2px solid red",
      }}
    >
      <svg
        width={"60vw"}
        height={"calc(65vw * 0.33)"}
        viewBox="0 0 220 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 9484">
          <text
            id="3"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="20"
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x="13.5508" y="24.2732">
              {data["duration"]}
            </tspan>
          </text>
          <text
            id="months"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="8.46744"
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x="29.1113" y="19.8257">
              {data["duration-unit"]}
            </tspan>
          </text>
          <text
            id="duration"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="6.55745"
            fontWeight="600"
            letterSpacing="0em"
          >
            <tspan x="20.5839" y="39.7961">
              {data["duration-cta"]}
            </tspan>
          </text>
          <text
            id="6"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="20"
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x="78.7422" y="24.855">
              {data["collection"]}
            </tspan>
          </text>
          <g id="collections">
            <text
              fill="#131313"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="8.46744"
              fontWeight="bold"
              letterSpacing="0em"
            >
              <tspan x="98.0824" y="19.8257">
                {data["collection-unit"]}
              </tspan>
            </text>
          </g>
          <text
            id="Free mints"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="6.55745"
            fontWeight="600"
            letterSpacing="0em"
          >
            <tspan x="89.8851" y="39.7961">
              {data["collection-price-cta"]}
            </tspan>
          </text>
          <text
            id="6_2"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="20"
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x="160.391" y="24.2732">
              {data["auction"]}
            </tspan>
          </text>
          <text
            id="1/1s"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="8.46744"
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x="176.258" y="19.8257">
              {data["auction-unit"]}
            </tspan>
          </text>
          <text
            id="Exclusive auctions"
            fill="#131313"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="6.55745"
            fontWeight="600"
            letterSpacing="0em"
          >
            <tspan x="150.36" y="39.7964">
              {data["auction-cta"]}
            </tspan>
          </text>
          <path id="Vector 52" d="M0.974609 30.3242L219.11 30.3242" stroke="#E0DDEA" strokeWidth="0.708928" />
          <path id="Vector 54" d="M68.6367 1.70312L68.6367 45.5371" stroke="#E0DDEA" strokeWidth="0.708928" />
          <path id="Vector 55" d="M144.729 1.70312L144.729 45.5371" stroke="#E0DDEA" strokeWidth="0.708928" />
          <path id="Vector 58" d="M212.691 1.70312L212.691 45.5371" stroke="#E0DDEA" strokeWidth="0.708928" />
          <path
            id="Rectangle 137"
            d="M0.803683 1.26657H219.196V45.5345H0.803683V1.26657Z"
            stroke="#E0DDEA"
            strokeWidth="0.708928"
          />
        </g>
      </svg>
    </div>
  );
}

export default MembershipDescTicketPartOne;
