import { useState, useEffect } from "react";
import "./step4.1.css";
import { useAddress } from "@thirdweb-dev/react";
import Confetti from "react-confetti";
import { EtherscanTransactionURL } from "../../../../constants/common-constants";

function LaunchMintSuccess({ artistId, membershipId, stepChange, transactionDetails, postLaunch, nftTitle, nftMedia }) {
  const address = useAddress();

  useEffect(() => {
    // console.log("launch/step4.1.js transactionDetails>>>", transactionDetails);
    // console.log("launch.step4.1.js>>>", nftMedia);
  }, []);

  return (
    <>
      <div style={{ zIndex: -1, position: "absolute", width: "100vw", height: "100vh" }}>
        <Confetti
          numberOfPieces={500}
          recycle={false}
          colors={[
            "#E4F1E8",
            "#C8E6E4",
            "#66C6CC",
            "#00A0AA",
            "#5999B2",
            "#EEA8B8",
            "#E1CAE1",
            "#937493",
            "#DE69A1",
            "#EA6383",
            "#FEA86D",
            "#FCD465",
            "#FFEC3F",
            "#FFF597",
            "#FFDE9D",
            "#F5AFD1",
            "#FFEDF6",
          ]}
        />
      </div>

      <div className="launch-mint-success">
        <div className="launch-mint-success-details-container">
          <div className="launch-mint-success-details-nft-media-container" style={{ height: nftMedia ? "67%" : "30%" }}>
            {!nftMedia ? (
              ""
            ) : nftMedia[0]["mediaType"] === "image" ? (
              <img style={{ height: "90%" }} src={nftMedia[0]["media"]} />
            ) : (
              <video height="90%" controls autoPlay={true} muted loop={true}>
                <source src={nftMedia[0]["media"]} type="video/mp4" />
              </video>
            )}
          </div>

          <div className="launch-mint-success-message-container" style={{ height: "20%" }}>
            <div className="launch-mint-success-message-heading-text">CONGRATULATIONS!</div>
            <div className="launch-mint-success-message-secondary-text">
              {postLaunch ? `You have minted ${nftTitle}.` : `You now own ${nftTitle}.`}
            </div>
            <div
              onClick={() => {
                let url = `${EtherscanTransactionURL}/${transactionDetails.transactionHash}`;
                window.open(url, "_blank");
              }}
              className="launch-mint-success-transaction-url-container"
            >
              View on etherscan
            </div>
          </div>
          <div className="launch-mint-success-navigation-container">
            <div className="launch-mint-success-button-container">
              <div
                className="launch-mint-success-button"
                onClick={() => {
                  stepChange(1);
                }}
              >
                <div className="launch-mint-success-button-text">Continue to Home</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaunchMintSuccess;
