import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import { useAddress } from "@thirdweb-dev/react";

import "./post-launch.css";
import PostLaunchStep1 from "./Step1/step1";
import PostLaunchStep2 from "./Step2/step2";
import LaunchStep3 from "../launch/Step3/step3";
import LaunchMintSuccess from "../launch/Step4.1/step4.1";
import LaunchMintFail from "../launch/Step4.2/step4.2";
import PostLaunchAuction from "./Step3/post-launch-auction";
import {
  ThirdWebEmbedUrlChainDetails,
  ThirdWebMintEmbedUrlERC1155,
  ThirdWebMintEmbedUrlERC721,
  ManifoldAuctionURL,
} from "../../../../constants/common-constants";

function PostLaunch({ nftDetails, thirdwebClientID }) {
  const address = useAddress();
  const params = useParams();
  const navigate = useNavigate();
  const [artistId, setArtistId] = useState(nftDetails[0]["artist_id"]);
  const [membershipId, setMembershipId] = useState(nftDetails[0]["membership_id"]);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [userNfts, setUserNfts] = useState([]);
  const [manifoldWidgetLoaded, setManifoldWidgetLoaded] = useState(false);
  const [passCount, setPassCount] = useState(0);
  const [dropLiveIndex, setDropLiveIndex] = useState(1);
  const currentTimeAndDate = new Date();

  // get the nfts that is already minted , minting, will mint
  let dropNftsData = [];
  let step1DropNftData = [];
  // console.log("post-launch.js all nftDetails>>>>>>>>>>", nftDetails);
  let i = 0;
  for (i = 0; i < nftDetails.length; i++) {
    let mint_start_date = new Date(`${nftDetails[i].mint_start}Z`);
    let mint_end_date = new Date(`${nftDetails[i].mint_end}Z`);
    let now = new Date();
    if (now >= mint_end_date) {
      dropNftsData.push({
        ...nftDetails[i],
        auction: nftDetails[i].mint_url ? true : false,
        live: false,
        future_drop: false,
        mint_count: 0,
        nft_details: [
          { media: nftDetails[i].media, mediaType: nftDetails[i].media_type, tokenId: nftDetails[i].token_id },
        ],
      });
    } else {
      // if the mint_end_date is not greater than now i.e. this is nft is currently minting or will mint next
      dropNftsData.push({
        ...nftDetails[i],
        auction: nftDetails[i].mint_url ? true : false,
        live: mint_start_date <= now ? true : false,
        future_drop: mint_start_date <= now ? false : true,
        mint_count: 0,
        nft_details: [
          { media: nftDetails[i].media, mediaType: nftDetails[i].media_type, tokenId: nftDetails[i].token_id },
        ],
      });

      if (i + 1 < nftDetails.length && mint_start_date <= now) {
        // next nft after currently minting will be upcoming nft
        dropNftsData.push({
          ...nftDetails[i + 1],
          auction: nftDetails[i + 1].mint_url ? true : false,
          live: false,
          future_drop: true,
          mint_count: 0,
          nft_details: [
            {
              media: nftDetails[i + 1].media,
              mediaType: nftDetails[i + 1].media_type,
              tokenId: nftDetails[i + 1].token_id,
            },
          ],
        });
      } else {
        if (i + 1 >= nftDetails.length) {
          // if the upcoming drop details is not present in the DB
          if (dropLiveIndex != 0 && mint_start_date < now) {
            setDropLiveIndex(0);
          }
        }
      }

      break;
    }
  }
  dropNftsData.reverse();

  let latest_drop_mint_start_time = new Date(`${dropNftsData[0].mint_start}Z`);
  let second_last_mint_end_date = new Date(`${dropNftsData[1].mint_end}Z`);

  // there are two cases in the post-launch
  // case 1 : if some drop is live minting/auction
  // case 2: nothing is live, drop scheduled for future date
  // case 3: if some drop/mint is live and upcoming details is not present in the DB
  if (latest_drop_mint_start_time > currentTimeAndDate && currentTimeAndDate > second_last_mint_end_date) {
    // case 1
    step1DropNftData = dropNftsData;
  } else {
    if (dropLiveIndex == 1) {
      // case 2
      // removing the first data because it is the details of upcoming nft which is not needed in step1
      step1DropNftData = dropNftsData.slice(1, dropNftsData.length);
    } else {
      // case 3
      // upcoming drop details is not present in the DB so don't need to delete the first entry
      step1DropNftData = dropNftsData;
    }
  }

  let [searchParams] = useSearchParams();
  const phase = searchParams.get("phase");
  const step = searchParams.get("step");
  let inviteCode = searchParams.get("invite_code");
  const [showStep, setShowStep] = useState(step);
  // console.log("postlaunch.js searchParams>>>>>>>>>", phase, step, inviteCode);

  const updateManifoldWidgetLoadingStatus = (status) => {
    setManifoldWidgetLoaded(status);
  };

  const getNavigationUrlParams = (phase, step) => {
    let urlParams = "";
    if (inviteCode) {
      urlParams = `?invite_code=${inviteCode}` + "&phase=" + String(phase) + "&step=" + String(step);
    } else {
      urlParams = "?phase=" + String(phase) + "&step=" + String(step);
    }
    return urlParams;
  };

  const changeStep = (step, data = {}) => {
    if (step == "3") {
      // console.log("mobile/post-launch.js nft details of live data>>>>..", dropNftsData[dropLiveIndex]);

      let url;
      if (dropNftsData[dropLiveIndex].mint_url) {
        url = ManifoldAuctionURL(dropNftsData[dropLiveIndex].mint_url);
      } else {
        if (dropNftsData[dropLiveIndex].token_id == "-1") {
          url = ThirdWebMintEmbedUrlERC721(
            dropNftsData[dropLiveIndex].contract_address,
            thirdwebClientID,
            JSON.stringify(ThirdWebEmbedUrlChainDetails)
          );
        } else {
          url = ThirdWebMintEmbedUrlERC1155(
            dropNftsData[dropLiveIndex].contract_address,
            thirdwebClientID,
            dropNftsData[dropLiveIndex].token_id,
            JSON.stringify(ThirdWebEmbedUrlChainDetails)
          );
        }
      }
      // console.log("mobile-post-launch.js thirdwebembed url>>>>>>>", url);
      window.open(url, "_blank", "noopener,noreferrer");
      changeStep(1);
      return;
    }
    if (step == "4.1" || step == "4.2") {
      return;
      setTransactionDetails(data);
    }
    navigate({
      pathname: "/mobile/" + String(params.membershipSlug),
      search: getNavigationUrlParams("post_launch", step),
    });
    setShowStep(step);
  };

  const setUserOwnedNftDetails = (userOwnedNftDetails, userOwnLaunchNft) => {
    let last_nft_mint_start_date = new Date(`${userOwnedNftDetails[userOwnedNftDetails.length - 1].mint_start}Z`);

    // add details of the upcoming drop if the last nft's minting stated if the next drop details present in the DB
    if (last_nft_mint_start_date <= currentTimeAndDate && dropLiveIndex == 1) {
      userOwnedNftDetails.push(dropNftsData[0]);
    }
    // console.log("post-launch.js nft data>>>>>>>>", userOwnedNftDetails, userOwnLaunchNft);

    // getting the data from the step1 set the data in the box according to count or the images of the nft
    if (userOwnLaunchNft) {
      setPassCount(userOwnedNftDetails[0]["mint_count"]);
      setUserNfts(userOwnedNftDetails);
    }
  };

  useEffect(() => {
    setArtistId(dropNftsData[1].artist_id);
    setMembershipId(dropNftsData[1].membership_id);
    setShowStep(step);
    let nftData = [];
    // console.log("length of the nfts and step>>>>>>>>", userNfts, step);
    if (userNfts.length === 0) {
      for (let i = 0; i < dropNftsData.length; i++) {
        nftData.push(dropNftsData[i]);
      }
      nftData.reverse();
      setUserNfts(nftData);
    }
  }, [step]);

  useEffect(() => {
    if (!address) {
      let nftData = [];
      for (let i = 0; i < dropNftsData.length; i++) {
        nftData.push(dropNftsData[i]);
      }
      nftData.reverse();
      setPassCount(0);
      setUserNfts(nftData);
    }
  }, [address]);

  return (
    <div className="mobile-post-launch-container">
      <>
        <div className="mobile-post-launch-step-container">
          {showStep == 1 ? (
            <PostLaunchStep1
              stepChange={changeStep}
              artistId={artistId}
              membershipId={membershipId}
              nftDetails={step1DropNftData}
              setUserOwnedNftDetails={setUserOwnedNftDetails}
            />
          ) : showStep == 2 ? (
            <PostLaunchStep2 stepChange={changeStep} artistId={artistId} membershipId={membershipId} />
          ) : showStep == 3 ? (
            dropNftsData[dropLiveIndex].mint_url ? (
              <PostLaunchAuction
                stepChange={changeStep}
                artistId={artistId}
                membershipId={membershipId}
                nftDetails={dropNftsData[dropLiveIndex]}
                updateManifoldWidgetLoadingStatus={updateManifoldWidgetLoadingStatus}
                manifoldWidgetLoaded={manifoldWidgetLoaded}
              />
            ) : (
              <LaunchStep3
                stepChange={changeStep}
                artistId={artistId}
                membershipId={membershipId}
                nftDetails={dropNftsData[dropLiveIndex]}
                postLaunch={true}
              />
            )
          ) : showStep == 4.2 ? (
            <LaunchMintFail
              stepChange={changeStep}
              artistId={artistId}
              membershipId={membershipId}
              transactionDetails={transactionDetails}
            />
          ) : showStep == 4.1 ? (
            <LaunchMintSuccess
              stepChange={changeStep}
              artistId={artistId}
              membershipId={membershipId}
              transactionDetails={transactionDetails}
              postLaunch={true}
              nftTitle={dropNftsData[dropLiveIndex].title}
              nftMedia={
                dropNftsData[dropLiveIndex].nft_details[0].tokenId != "-1"
                  ? dropNftsData[dropLiveIndex].nft_details
                  : ""
              }
            />
          ) : (
            ""
          )}
        </div>
      </>
    </div>
  );
}

export default PostLaunch;
