import { Ethereum, Sepolia } from "@thirdweb-dev/chains";

// PROD CONFIG
const _PROD_API = "https://api.tasseo.xyz/api/v1";
// const _PROD_API = "http://192.168.183.238:8000";
// const _PROD_API = "http://localhost:8000";

export const API = {
  GetPhaseDetails: _PROD_API + "/wizard/",
  GetStepDetails: _PROD_API + "/wizard/step",
  GetInitialData: _PROD_API + "/wizard/initial_data",
  GetArtistDetails: _PROD_API + "/artist/",
  GetMembershipDetails: _PROD_API + "/artist/membership",
  GetMemberDetails: _PROD_API + "/member/",
  StoreEmailAddress: _PROD_API + "/member/email",
  ValidateInviteCode: _PROD_API + "/member/referral",
  StoreTransactionDetails: _PROD_API + "/member/transaction",
  GetCollectorDetails: _PROD_API + "/artist/drop_mints_count",
  GetArtistThirdWebClientID: _PROD_API + "/artist/thirdweb_clinet_id",
};

export const ThirdWebMintEmbedUrlERC721 = (
  contactAddress,
  clientID,
  chain
) => `https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?theme=dark&primaryColor=black
&contract=${contactAddress}
&clientId=${clientID}
&chain=${chain}`;

export const ThirdWebMintEmbedUrlERC1155 = (
  contactAddress,
  clientID,
  tokenId,
  chain
) => `https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc1155.html?theme=dark&primaryColor=black
&contract=${contactAddress}
&clientId=${clientID}
&tokenId=${tokenId}
&chain=${chain}`;

export const ManifoldAuctionURL = (listingID) => `https://gallery.manifold.xyz/listing?listingId=${listingID}`;

// DEV
// export const ThirdWebChain = Sepolia;
// export const ThirdWebClientID = "9aeb1447290db7265b592a62001de41d"; // for testing;
// export const OpenseaCollectionLink = "https://testnets.opensea.io/collection/moments-fragments-1";
// export const OpenseaViewNFTTokenURL = "https://testnets.opensea.io/assets/sepolia";
// export const EtherscanTransactionURL = "https://sepolia.etherscan.io/tx";
// export const ThirdWebEmbedUrlChainDetails = {
//   name: "Sepolia",
//   chain: "ETH",
//   rpc: ["https://11155111.rpc.thirdweb.com/${THIRDWEB_API_KEY}"],
//   nativeCurrency: {
//     name: "Sepolia Ether",
//     symbol: "ETH",
//     decimals: 18,
//   },
//   shortName: "sep",
//   chainId: 11155111,
//   testnet: true,
//   slug: "sepolia",
//   icon: {
//     url: "ipfs://QmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9/ethereum/512.png",
//     width: 512,
//     height: 512,
//     format: "png",
//   },
// };

// PROD
// export const ThirdWebChain = {
//   ...Ethereum,
//   rpc: ["https://mainnet.infura.io/v3/0b4ae329188e405aaac50f8d5d25db58"],
// };

export const ThirdWebChain = Ethereum;
export const ThirdWebClientID = "9aeb1447290db7265b592a62001de41d"; // testing
export const OpenseaCollectionLink = "";
export const OpenseaViewNFTTokenURL = "https://opensea.io/assets/ethereum";
export const EtherscanTransactionURL = "https://etherscan.io/tx";
export const ThirdWebEmbedUrlChainDetails = {
  name: "Ethereum Mainnet",
  chain: "ETH",
  rpc: ["https://ethereum.rpc.thirdweb.com/${THIRDWEB_API_KEY}"],
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
  },
  shortName: "eth",
  chainId: 1,
  testnet: false,
  slug: "ethereum",
  icon: {
    url: "ipfs://QmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9/ethereum/512.png",
    height: 512,
    width: 512,
    format: "png",
  },
};
