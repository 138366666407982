import { useState, useEffect } from "react";
import "./step1.css";
import { useAddress } from "@thirdweb-dev/react";
import WizardAPI from "../../wizard-api";
import PriceDetailsForAllowlist from "../../../common-components/price-details-for-allowlist/price-details-for-allowlist";
import PriceDetails from "../../../common-components/price-details/price-details";
import PriceDetailsLimitedEdition from "../../../common-components/price-details-limited-edition/price-details-limited-edition";
import PriceDetailsLimitedEditionForAllowlist from "../../../common-components/price-details-limited-edition-for-allowlist/price-details-limited-edition-for-allowlist";
import StepLoader from "../../../common-components/step-loader/step-loader";

function LaunchStep1({ artistId, membershipId, stepChange, nftDetails }) {
  const _HexValues = ["4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E"];
  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});
  const [allowlisted, setAllowlisted] = useState(false);
  const [collectors, setCollectors] = useState([]);
  const address = useAddress();

  const fetchStepDetails = async () => {
    setLoading(true);
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "launch", 1);
    // console.log("launch.js./step1.js step details>>>>>", JSON.stringify(stepDetailsRes["data"], 2, ""));
    // console.log(">>>>>>>>>>", stepDetailsRes["data"]["membership-sub-description"].replace("<br/>", "\n"));
    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      setStepDetails(stepDetailsRes["data"]);
    } else {
      alert("Something went wrong. Please refresh page.");
    }
    await fetchCollectorsDetails();
  };

  const fetchCollectorsDetails = async () => {
    const collectorsDetailsRes = await WizardAPI.getCollectorDetails(artistId, membershipId, nftDetails.drop_number);
    // console.log("launch.js./step1.js collectors details>>>>>>>>>>", collectorsDetailsRes);
    if (collectorsDetailsRes["success"] && collectorsDetailsRes["code"] === 200) {
      const data = collectorsDetailsRes["data"];
      let collectorList = data && data.length ? data[0]["collectors"] : [];
      setCollectors(collectorList);
      setLoading(false);
    }
  };

  const getGradient = () => {
    let color1 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color1 += y;
    }
    let color2 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color2 += y;
    }
    // keep light color first
    if (color1 < color2) {
      let t = color1;
      color1 = color2;
      color2 = t;
    }
    let angle = Math.round(Math.random() * 100);
    return "linear-gradient(" + angle + "deg, " + color1 + ", " + color2 + ")";
  };

  // -------------------------------------------------
  // useEffects
  // -------------------------------------------------

  useEffect(() => {
    if (collectors.length < 7) return;
    setTimeout(() => {
      try {
        console.log("changing class...");
        let el0 = document.getElementById("collector-pfp-0");
        let el5 = document.getElementById("collector-pfp-5");
        el0.className = "launch-step1-collector-pfp launch-step1-collector-pfp-zero-size";
        el5.className = "launch-step1-collector-pfp launch-step1-collector-pfp-full-size";
      } catch (e) {
        //
      }
    }, 1300);
  }, [collectors]);

  useEffect(() => {
    // console.log("launch/step1.js>>>>>>>>>>", nftDetails);
    fetchStepDetails();
  }, []);

  useEffect(() => {
    if (address) {
      WizardAPI.getMemberDetails(artistId, membershipId, address, "").then((response) => {
        if (response && response.data && response.data.on_allowlist === true) setAllowlisted(true);
      });
    } else {
      setAllowlisted(false);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="launch-step1-container">
          <div className="launch-step1-membership-desc-container">
            <div className="launch-step1-membership-main-desc-container">
              <img
                className="launch-step1-membership-main-desc"
                src={stepDetails["membership-description"]}
                alt="desc"
              />
            </div>
            <div className="launch-step1-membership-sub-desc-container">
              <div className="launch-step1-membership-sub-desc">{stepDetails["membership-sub-description"]}</div>
            </div>
          </div>
          {allowlisted === true ? (
            stepDetails["limited-edition-raffle"] ? (
              <PriceDetailsLimitedEditionForAllowlist data={stepDetails} launch={true} />
            ) : (
              <PriceDetailsForAllowlist data={stepDetails} launch={true} />
            )
          ) : stepDetails["limited-edition-raffle"] ? (
            <PriceDetailsLimitedEdition data={stepDetails} launch={true} />
          ) : (
            <PriceDetails data={stepDetails} launch={true} />
          )}
          <div className="launch-step1-navigation-container">
            <div className="launch-step1-collector-container">
              {collectors.length > 0 ? (
                <>
                  <div className="launch-step1-collector-text-container">Collected by</div>
                  <div className="launch-step1-collector-details-container">
                    {collectors.map((address, index) => {
                      if (index <= 5) {
                        return (
                          <div
                            key={index}
                            id={`collector-pfp-${index}`}
                            className="launch-step1-collector-pfp"
                            style={{ background: getGradient() }}
                            onClick={() => {
                              window.open(
                                `https://opensea.io/assets/ethereum/${nftDetails.contract_address}`,
                                "_blank"
                              );
                            }}
                          ></div>
                        );
                      } else {
                        if (index === 6) {
                          return (
                            <div
                              key={index}
                              className="launch-step1-collector-size"
                              onClick={() => {
                                window.open(
                                  `https://opensea.io/assets/ethereum/${nftDetails.contract_address}`,
                                  "_blank"
                                );
                              }}
                            >
                              +{collectors.length - 5 <= 9 ? "0" : ""}
                              {collectors.length - 5}
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="launch-step1-mint-container">
              <div className="launch-step1-button-container">
                <div className="launch-step1-button" onClick={() => stepChange(2)}>
                  <div className="launch-step1-button-text">{stepDetails["button-cta"]}</div>
                </div>
              </div>
              <div className="launch-step1-thirdweb-container">
                <img
                  className="launch-step1-thirdweb-logo"
                  src={require("../../../../assets/thirdweb-logo.webp")}
                  alt="thirdweb-logo"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LaunchStep1;
