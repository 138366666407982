import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useAddress } from "@thirdweb-dev/react";

import "./launch.css";
import LaunchStep1 from "./Step1/step1";
import LaunchStep2 from "./Step2/step2";
import LaunchStep3 from "./Step3/step3";
import LaunchMintSuccess from "./Step4.1/step4.1";
import LaunchMintFail from "./Step4.2/step4.2";
import PostLaunchNftContainer from "../../common-components/post-launch-nft-container/post-launch-nft-container";
import { FindNftOwned } from "../Post-launch/Step1/step1";

function Launch({ nftDetails, passLimit }) {
  const params = useParams();
  const navigate = useNavigate();
  const [artistId, setArtistId] = useState(nftDetails[0]["artist_id"]);
  const [membershipId, setMembershipId] = useState(nftDetails[0]["membership_id"]);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [userNfts, setUserNfts] = useState([]);
  const [passCount, setPassCount] = useState(passLimit);
  const address = useAddress();

  let [searchParams] = useSearchParams();
  const phase = searchParams.get("phase");
  const step = searchParams.get("step");
  let inviteCode = searchParams.get("invite_code");
  const [showStep, setShowStep] = useState(step);
  // console.log("launch.js searchParams >>>>>>>", phase, step, inviteCode);

  // setting the details of the nfts
  //setting launch nft details
  let dropNftsData = [];
  dropNftsData.push({
    ...nftDetails[0],
    auction: false,
    live: true,
    future_drop: false,
    mint_count: 0,
    nft_details: [{ media: "", mediaType: nftDetails[0].media_type, tokenId: nftDetails[0].token_id }],
  });

  // setting first drop details
  dropNftsData.push({
    ...nftDetails[1],
    auction: false,
    live: false,
    future_drop: true,
    mint_count: 0,
    nft_details: [{ media: nftDetails[1].media, mediaType: nftDetails[1].media_type, tokenId: nftDetails[1].token_id }],
  });

  // fetching launch nft owned by user
  const [launchContractNftData, launchContractNftDataLoading, launchContractNftDataError] = FindNftOwned(
    nftDetails[0].contract_address,
    address
  );

  // console.log(
  //   "launch.js launch nft details>>>>",
  //   launchContractNftData,
  //   launchContractNftDataLoading,
  //   launchContractNftDataError
  // );

  const getNavigationUrlParams = (phase, step) => {
    let urlParams = "";
    if (inviteCode) {
      urlParams = `?invite_code=${inviteCode}` + "&phase=" + String(phase) + "&step=" + String(step);
    } else {
      urlParams = "?phase=" + String(phase) + "&step=" + String(step);
    }
    return urlParams;
  };

  const changeStep = (step, data = {}) => {
    if (step == "4.1" || step == "4.2") {
      setTransactionDetails(data);
    }
    navigate({
      pathname: "/" + String(params.membershipSlug),
      search: getNavigationUrlParams("launch", step),
    });
    setShowStep(step);
  };

  // useEffect to set the initial data or change the step
  useEffect(() => {
    setShowStep(step);
    let nftData = [];
    if (userNfts.length === 0) {
      for (let i = 0; i < dropNftsData.length; i++) {
        nftData.push(dropNftsData[i]);
      }
      setUserNfts(nftData);
    }
    // console.log("launch.js user nfts>>>>>", nftData, passLimit);
  }, [step]);

  // useEffect to get the mint count of launch nft
  useEffect(() => {
    if (!launchContractNftDataLoading && launchContractNftData && !launchContractNftDataError) {
      // console.log("launch.js useEffect launch nft data>>>>>", launchContractNftData);
      dropNftsData[0]["nft_details"] = [
        { media: nftDetails[0].media, mediaType: nftDetails[0].media_type, tokenId: nftDetails[0].token_id },
      ];
      for (let j = 0; j < launchContractNftData.length; j++) {
        if (launchContractNftData[j]["type"] === "ERC1155") {
          // if the token is ERC1155 then only need the count of the nft user owned
          dropNftsData[0]["mint_count"] = Number(launchContractNftData[j]["quantityOwned"]);
        } else {
          // if the token is ERC721 then need image and token id
          dropNftsData[0]["mint_count"] = j + 1;
          dropNftsData[0]["nft_details"][j] = {
            media: launchContractNftData[j]["metadata"]["image"],
            mediaType: "image",
            tokenId: launchContractNftData[j]["metadata"]["id"],
          };
        }
      }
      setUserNfts(dropNftsData);
    }
  }, [launchContractNftData]);

  // useEffect to check if the wallet is connected or not
  useEffect(() => {
    // console.log("launch.js userEffect address>>>>>>>", address, launchContractNftDataLoading);

    // if the address/wallet connected is present then set the media of launch nft to loading media
    // if will change to original media when the contract details is loaded
    // if the wallet is not connected nft media of the launch nft is shown

    // setting launch nft details
    let nftData = [];
    nftData.push({
      ...nftDetails[0],
      auction: false,
      live: true,
      future_drop: false,
      mint_count: 0,
      nft_details: [
        {
          media: address && launchContractNftDataLoading ? "" : nftDetails[0].media,
          mediaType: nftDetails[0].media_type,
          tokenId: nftDetails[0].token_id,
        },
      ],
    });

    // setting first drop details
    nftData.push({
      ...nftDetails[1],
      auction: false,
      live: false,
      future_drop: true,
      mint_count: 0,
      nft_details: [
        { media: nftDetails[1].media, mediaType: nftDetails[1].media_type, tokenId: nftDetails[1].token_id },
      ],
    });
    setUserNfts(nftData);
  }, [address]);

  return (
    <div className="launch-container">
      {showStep == 4.1 ? (
        <LaunchMintSuccess
          stepChange={changeStep}
          artistId={artistId}
          membershipId={membershipId}
          transactionDetails={transactionDetails}
          postLaunch={false}
          nftTitle={nftDetails[0].title}
          nftMedia={[
            { media: nftDetails[0].media, mediaType: nftDetails[0].media_type, tokenId: nftDetails[0].token_id },
          ]}
        />
      ) : (
        <>
          <PostLaunchNftContainer userNfts={userNfts} width={"39.2vw"} passCount={passCount} />
          <div className="launch-nft-details-container">
            {showStep == 1 ? (
              <LaunchStep1
                stepChange={changeStep}
                artistId={artistId}
                membershipId={membershipId}
                nftDetails={nftDetails[0]}
              />
            ) : showStep == 2 ? (
              <LaunchStep2 stepChange={changeStep} artistId={artistId} membershipId={membershipId} />
            ) : showStep == 3 ? (
              <LaunchStep3
                stepChange={changeStep}
                artistId={artistId}
                membershipId={membershipId}
                nftDetails={nftDetails[0]}
                postLaunch={false}
              />
            ) : showStep == 4.2 ? (
              <LaunchMintFail
                stepChange={changeStep}
                artistId={artistId}
                membershipId={membershipId}
                transactionDetails={transactionDetails}
              />
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Launch;
